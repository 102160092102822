import './index.css'
import React from "react"
import Loadable from "@loadable/component";

const LoadableShell = Loadable(() =>
  import("../components/Shell")
);

function IndexPage() {
    return (
        <>
        <LoadableShell />
        </>
    )
}

export default IndexPage
